<template>
  <el-select
    v-model="syncModel"
    :multiple="multiple"
    no-data-text="暫無數據"
    placeholder="請選擇訂單運送方式"
    clearable
    value-key="id"
    @change="$emit('change')"
  >
    <el-option
      v-for="item in classTicketList"
      :key="item.id"
      :label="item.name"
      :value="getKeyValue(item)"
    />
  </el-select>
</template>

<script>
import { GetDelivery, GetDeliveryCount } from '@/api/ecommerce/delivery.js'
import { mapGetters } from 'vuex'
export default {
  name: 'DeliverySelect',
  props: ['model', 'multiple', 'objKey'],
  data: () => ({
    classTicketList: [],
  }),

  computed: {
    ...mapGetters(['shop']),
    syncModel: {
      get () {
        return this.model
      },
      set (data) {
        this.$emit('update:model', data)
      },
    },
  },

  async mounted () {
    await this.getClassTicket()
  },

  methods: {
    getKeyValue (item) {
      if (!this.objKey) return item
      return item[this.objKey]
    },
    async getClassTicket () {
      const max = await GetDeliveryCount({ shopId: this.shop })
      let start = 0
      const [res, err] = await GetDelivery({
        shopId: this.shop,
        start,
        limit: 50,
      })
      this.classTicketList = res
      console.log(1, res)
      if (err) return this.$message.error(err)

      while (this.classTicketList.length < max) {
        start += 50
        const res = await GetDelivery({
          shopId: this.shop,
          start,
          limit: 50,
        })

        this.classTicketList.push(...res)
      }
    },
  },
}
</script>
